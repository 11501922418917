<style>
html {
    overflow-y: auto;
}
@media (min-width:1300px) and (max-width:1860px){
  .left-side {
    /* margin-top: 51px; */
    padding-top: 189px;
}
}
@media (min-width:1264px) and (max-width:1299px){
  .left-side {
    padding-top: 189px;
}
}
@media (min-width:1243px) and (max-width:1263px){
  .left-side {
    padding-top: 100x;
}
}
@media (min-width:1263px) and (max-width:1280px){
  .left-side {
    padding-top: 310px;
}
.logo-bottom{
  margin-bottom: 10px;
}
}
@media (max-width:1244px){
  .left-side {
    padding-top:10px;
}
}
</style>

<template>
  <v-card>
    <v-img
      src="@/assets/bg_overlay_register.png"
      lazy-src="@/assets/bg_overlay_register_small.jpg"
      height="100vh"
      width="100%"
      class="bg-cover"
    >
      <v-container
        fluid
        class="d-flex flex-row justify-center left-side"
        style="height: 100%; width: 100%; color: white; max-height: 100%; overflow: auto;"
        :class="[
          $vuetify.breakpoint.xs ? 'pa-0' : '',
          $vuetify.breakpoint.lgAndUp ? 'align-center' : '',
        ]"
      >
        <div
          :style="
            $vuetify.breakpoint.smAndDown
              ? 'width: 100%; padding: 20px;'
              : 'width: 50%; padding: 30px'
          "
        >
          <div
            class="d-flex flex-row mb-10 logo-bottom" 
            :class="$vuetify.breakpoint.xs ? 'flex-wrap' : ''"
          >
            <div class="mr-5 mt-5">
              <v-img src="@/assets/logo.svg" width="287" height="49" contain />
            </div>
            <div class="mt-5">
              <v-img
                src="@/assets/logo_legacy.svg"
                width="226"
                height="49"
                contain
              />
            </div>
          </div>
          <div class="app-bold-font font-40" style="line-height: 1.4;">
            Welcome to the partnership of aKinder™Volunteer and Legacy Response!
          </div>
          <div class="app-bold-font font-20 mt-5">
            Volunteers are the most powerful Force for Good in the world!
          </div>
          <div class="d-flex flex-row mt-5">
            <div class="font-14">
              Every day volunteers impact the lives of those in need and help
              mission driven nonprofits as they strive to make their community a
              better place to live. We want you to be part of that movement! By
              registering on aKinder™Volunteer you can become part of an
              integrated volunteer/nonprofit network of like minded individuals,
              families and local businesses.
            </div>
            <div class="font-14 ml-10">
              Over the next 90 days we will be hard at work training nonprofits
              on the use of our social network driven platform. As nonprofits
              register and complete training we will then start matching you
              with the nonprofit/nonprofits who are looking for your skills,
              passion and availability. Please be patient as we get the glitches
              out of our system. If you have any issues registering please call
              us at 508-202-3165.
            </div>
          </div>
          <div>
            <v-btn
              color="#2BB066"
              @click="goToLogin"
              class="white--text my-10 text-none"
              v-text="'Register Now!'"
              style="width: 300px;"
              :small="$vuetify.breakpoint.xs"
            />
          </div>
          <div class="mt-2 pb-5">
            Thank you!
          </div>
        </div>
        <div style="width: 50%;"></div>
      </v-container>
    </v-img>
  </v-card>
</template>
<script>
export default {
  methods: {
    goToLogin() {
      this.$router.push({ name: "login", query: this.$route.query });
    },
  },
};
</script>
<style scoped></style>
